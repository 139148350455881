import React from "react";
import { Heading } from "theme-ui";
import useName from "../hooks/use-name";
import Img from "../assets/scott-gobin-photography.png"


const Logo = () => {
  const name = useName();

  return (
    // The import result is the URL of your image
    <img className="nav-logo" src={Img} alt="Scott-Gobin Photography" />

  )

};

  

export default Logo;
